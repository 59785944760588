.slider {
  --slider-height: 30px;
  --slider-track-height: 5px;
  --slider-thumb-border-width: 2px;
  --slider-track-offset: calc(
    (var(--slider-height) - var(--slider-track-height)) / 2
  );
}

.slider.disabled,
.slider:hover.disabled,
.slider:active.disabled {
  filter: grayscale(1);
  opacity: 0.3;
}

.slider.disabled .slider-track,
.slider.disabled .slider-thumb,
.slider:hover.disabled .slider-track,
.slider:hover.disabled .slider-thumb,
.slider:active.disabled .slider-track,
.slider:active.disabled .slider-thumb {
  cursor: not-allowed;
}

.slider-horizontal {
  @apply w-full;

  height: var(--slider-height);
}

.slider-horizontal .slider-track {
  top: var(--slider-track-offset);
  height: var(--slider-track-height);
}

.slider-horizontal .slider-thumb {
  top: 1px;
  width: calc(var(--slider-height) - var(--slider-thumb-border-width));
  height: calc(var(--slider-height) - var(--slider-thumb-border-width));
  line-height: calc(var(--slider-height) - var(--slider-track-height));
}

.slider-vertical {
  height: 500px;
  width: var(--slider-height);
}

.slider-vertical .slider-thumb {
  left: 1px;
  width: calc(var(--slider-height) - var(--slider-thumb-border-width));
  height: calc(var(--slider-height) - var(--slider-thumb-border-width));
  line-height: calc(var(--slider-height) - var(--slider-track-height));
}

.slider-vertical .slider-track {
  left: var(--slider-track-offset);
  width: var(--slider-track-height);
}

.slider-primary .slider-thumb {
  @apply bg-primary border-2 border-solid border-white;

  box-shadow: 0 0 15px theme('colors.primary');
}

.slider-primary .slider-thumb-active {
  @apply bg-primary;
}

.slider-primary .slider-track {
  @apply bg-primary;
}

.slider-secondary .slider-thumb {
  @apply bg-secondary border-2 border-solid border-white;

  box-shadow: 0 0 15px theme('colors.secondary');
}

.slider-secondary .slider-thumb-active {
  @apply bg-secondary;
}

.slider-secondary .slider-track {
  @apply bg-secondary;
}

.slider-thumb {
  font-size: 0.9em;
  text-align: center;
  color: #fff;
  border: var(--slider-thumb-border-width) solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.slider-thumb:focus-visible {
  @apply outline-none;
}

.slider:hover .slider-thumb {
  cursor: grab;
}

.slider:active .slider-thumb {
  cursor: grabbing;
}

.slider-track {
  @apply relative cursor-pointer;
}

.slider-track.slider-track-1 {
  @apply bg-gray-200;
}
